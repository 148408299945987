import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import Container from "./container"
import { FlexBox, Title1, styles } from "./styles"
import SVG from "../images/annamilcarz-logo-hero.inline.svg"
import Image from "./image"
import { transparentize } from "polished"

const overlay = transparentize(0.4, "#111E2F")

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 40vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: ${overlay};
  }
}
`

const PageTitleBox = styled.div`
  padding-left: 20px;
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &::before {
    content: "";
    height: 30px;
    width: 4px;
    background-color: ${styles.colorSecondary};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CategoriesBox = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18%;
  transform: translateY(50%);
  z-index: 1;

  span {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    margin: 5px 25px;
    text-transform: uppercase;

    a {
      color: inherit;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: ${styles.colorSecondary};
      }
    }
  }
`

const TopImage = ({ title }) => {
  const topimagequery = useStaticQuery(graphql`
    query topimagequery {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 223 } }) {
        nodes {
          id
          acf {
            zdjecie_glowne_obraz {
              title
            }
          }
        }
      }
      allWordpressCategory {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `)

  const media =
    topimagequery.allWordpressAcfPages.nodes[0].acf.zdjecie_glowne_obraz
  const categories = topimagequery.allWordpressCategory.edges

  return (
    <Wrapper className={"hero"}>
      <BackgroundWrapper>
        <Image filename={media.title} />
      </BackgroundWrapper>
      <PageTitleBox>
        <Title1 color={"#fff"}>{title}</Title1>
      </PageTitleBox>
      <CategoriesBox>
        <Container>
          <FlexBox justify={"center"} align={"center"} wrap={"wrap"}>
            <span>
              <Link to={`blog`}>#Wszystkie</Link>
            </span>
            {categories.map(({ node }) => cat(node))}
          </FlexBox>
        </Container>
      </CategoriesBox>
    </Wrapper>
  )
}

export default TopImage

const cat = node => {
  if (node.name !== "Bez kategorii") {
    return (
      <span>
        <Link to={`kategoria/${node.slug}`}>#{node.name}</Link>
      </span>
    )
  }
}
