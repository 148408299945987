import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Article from "../components/article"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionA } from "../components/styles"
import Container from "../components/container"
import Grid from "../components/grid"
import TopImage from "../components/top-image"
import Fade from "react-reveal/Fade"

const ArticlesContainer = styled(Container)`
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1366px) {
    max-width: 1270px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const ArticleGrid = styled(Grid)`
  .react-reveal {
    &:nth-child(3n + 1) {
      animation-delay: 300ms !important;
    }

    &:nth-child(3n + 2) {
      animation-delay: 500ms !important;
    }

    &:nth-child(3n + 3) {
      animation-delay: 700ms !important;
    }
  }
`

const Category = ({ data, pageContext }) => {
  const { categoryName, index } = pageContext

  return (
    <Layout>
      <SEO title={categoryName} />
      <TopImage title={categoryName} />
      <SectionA p={"67px 0"}>
        <ArticlesContainer>
          <ArticleGrid
            className={"articleGrid"}
            xs={"1fr"}
            lg={"50% 50%"}
            xl={"33.33% 33.33% 33.33%"}
            columnGap={"0"}
            rowGap={"0"}
          >
            {data.allWordpressPost.edges.map(({ node }) => (
              <Fade>
                <Article
                  date={node.date}
                  title={node.title}
                  short={node.acf.zajawka}
                  excerpt={node.acf.artykul}
                  slug={node.slug}
                  social={"facebook"}
                  categories={node.categories.map(category => category.name)}
                  categoriesSlug={node.categories.map(
                    category => category.slug
                  )}
                  image={
                    node.featured_media ? node.featured_media.title : "Header"
                  }
                />
              </Fade>
            ))}
          </ArticleGrid>
        </ArticlesContainer>
      </SectionA>
    </Layout>
  )
}

export default Category

export const categoryQuery = graphql`
  query($categoryName: String!) {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $categoryName } } } }
    ) {
      edges {
        node {
          date
          title
          slug
          categories {
            name
            slug
          }
          featured_media {
            title
          }
          acf {
            artykul
            zajawka
          }
        }
      }
    }
  }
`
